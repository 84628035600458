import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ScrollAnimation from 'react-animate-on-scroll';
import Navigation from '../components/Nav';
import NavigationEn from '../components/Nav.en';
import Footer from '../components/Footer';

const encode = (data) =>
	Object.keys(data)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join('&');

export const PageTemplateEn = ({ title, content, template, featImage, pageCurrentLang }) => {
	const [submitedEn, setSubmitEn] = useState(false);
	const [nameEn, setNameEn] = useState('');
	const [lastnameEn, setLastnameEn] = useState('');
	const [emailEn, setEmailEn] = useState('');
	const [subjectEn, setSubjectEn] = useState('');
	const [messageEn, setMessageEn] = useState('');

	const [submited, setSubmit] = useState(false);
	const [nameSr, setNameSr] = useState('');
	const [lastnameSr, setLastnameSr] = useState('');
	const [emailSr, setEmailSr] = useState('');
	const [subjectSr, setSubjectSr] = useState('');
	const [messageSr, setMessageSr] = useState('');

	function submitFormEn(e) {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'Contact', nameEn, lastnameEn, emailEn, subjectEn, messageEn }),
		})
			.then(() => setSubmitEn(true))
			.catch((error) => alert(error));

		e.preventDefault();
	}
	function submitFormSr(e) {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'Kontakt', nameSr, lastnameSr, emailSr, subjectSr, messageSr }),
		})
			.then(() => setSubmit(true))
			.catch((error) => alert(error));

		e.preventDefault();
	}
	return (
		<section
			className={`section section--gradient ${template === 'template-contact.php' ? 'contactPage ' : ''} ${
				template === 'template-about.php' ? 'aboutUsPage ' : ''
			} `}
		>
			<div className="innerSection">
				{template === 'template-contact.php' ? (
					<div className="contactWrapper">
						<div className="imageWrapper" style={{ display: 'block', width: '100%', height: '100%' }}>
							<Img
								// imgStyle={{}}
								style={{ height: '100%' }}
								imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
								fluid={featImage.localFile.childImageSharp.fluid}
							/>
						</div>
						<div className="formWrapper">
							<div className="titleWrapper">
								<h1 className="title">Contact</h1>
								{/* <h1 className="title">{title}</h1> */}
							</div>
							{pageCurrentLang === 'en_US' ? (
								<form
									className="contactForm"
									name="Contact"
									method="POST"
									data-netlify="true"
									data-netlify-honeypot="bot-field"
									// action=""
									onSubmit={submitFormEn}
								>
									<label htmlFor="form-name">
										<input type="hidden" name="form-name" value="Contact" aria-label="form-name" />
									</label>
									<p className="hidden">
										<label htmlFor="bot-field" className="hiddenField">
											Don’t fill this out if you’re human: <input name="bot-field" />
										</label>
									</p>
									<label htmlFor="nameEn" className="half_width ">
										<input
											type="text"
											placeholder="Name *"
											name="nameEn"
											aria-label="Name"
											value={nameEn}
											required
											onChange={(e) => setNameEn(e.target.value)}
										/>
									</label>
									<label htmlFor="lastnameEn" className="half_width second_width">
										<input
											type="text"
											placeholder="Last Name *"
											name="lastnameEn"
											aria-label="Last Name"
											value={lastnameEn}
											required
											onChange={(e) => setLastnameEn(e.target.value)}
										/>
									</label>
									<label htmlFor="emailEn">
										<input
											type="email"
											placeholder="Email *"
											aria-label="email en"
											name="emailEn"
											value={emailEn}
											required
											onChange={(e) => setEmailEn(e.target.value)}
										/>
									</label>
									<label htmlFor="subjectEn">
										<input
											type="subject"
											placeholder="Subject *"
											aria-label="subject en"
											name="subjectEn"
											value={subjectEn}
											required
											onChange={(e) => setSubjectEn(e.target.value)}
										/>
									</label>
									<label htmlFor="messageEn">
										<textarea
											placeholder="Your message *"
											aria-label="message"
											name="messageEn"
											rows="8"
											value={messageEn}
											required
											onChange={(e) => setMessageEn(e.target.value)}
										/>
									</label>
									<label htmlFor="submit" className="submitBtnWrapper">
										<button className="submitButton" type="submit" aria-label="submit">
											Send
										</button>
										<p className={`${submitedEn ? 'submited' : ''}`}>
											Thank you, Your message has been sent!
										</p>
									</label>
								</form>
							) : (
								<form
									className="contactForm"
									name="Contact"
									method="POST"
									data-netlify="true"
									data-netlify-honeypot="bot-field"
									// action=""
									onSubmit={submitFormEn}
								>
									<label htmlFor="form-name">
										<input type="hidden" name="form-name" value="Contact" aria-label="form-name" />
									</label>
									<p className="hidden">
										<label htmlFor="bot-field" className="hiddenField">
											Don’t fill this out if you’re human: <input name="bot-field" />
										</label>
									</p>
									<label htmlFor="nameEn" className="half_width ">
										<input
											type="text"
											placeholder="Name *"
											name="nameEn"
											aria-label="Name"
											value={nameEn}
											required
											onChange={(e) => setNameEn(e.target.value)}
										/>
									</label>
									<label htmlFor="lastnameEn" className="half_width second_width">
										<input
											type="text"
											placeholder="Last Name *"
											name="lastnameEn"
											aria-label="Last Name"
											value={lastnameEn}
											required
											onChange={(e) => setLastnameEn(e.target.value)}
										/>
									</label>
									<label htmlFor="emailEn">
										<input
											type="email"
											placeholder="Email *"
											aria-label="email en"
											name="emailEn"
											value={emailEn}
											required
											onChange={(e) => setEmailEn(e.target.value)}
										/>
									</label>
									<label htmlFor="subjectEn">
										<input
											type="subject"
											placeholder="Subject *"
											aria-label="subject en"
											name="subjectEn"
											value={subjectEn}
											required
											onChange={(e) => setSubjectEn(e.target.value)}
										/>
									</label>
									<label htmlFor="messageEn">
										<textarea
											placeholder="Your message *"
											aria-label="message"
											name="messageEn"
											rows="8"
											value={messageEn}
											required
											onChange={(e) => setMessageEn(e.target.value)}
										/>
									</label>
									<label htmlFor="submit" className="submitBtnWrapper">
										<button className="submitButton" type="submit" aria-label="submit">
											Send
										</button>
										<p className={`${submitedEn ? 'submited' : ''}`}>
											Thank you, Your message has been sent!
										</p>
									</label>
								</form>
								// <form
								// 	className="contactForm"
								// 	name="Kontakt"
								// 	method="POST"
								// 	data-netlify="true"
								// 	data-netlify-honeypot="bot-field"
								// 	// action=""
								// 	onSubmit={submitFormSr}
								// >
								// 	<label htmlFor="form-name">
								// 		<input type="hidden" name="form-name" value="Kontakt" aria-label="form-name" />
								// 	</label>
								// 	<label htmlFor="bot-field" className="hiddenField">
								// 		Ne popunjavajte ovo polje ako ste osoba: <input name="bot-field" />
								// 	</label>
								// 	<label htmlFor="nameSr" className="half_width">
								// 		<input
								// 			type="text"
								// 			placeholder="Ime *"
								// 			name="nameSr"
								// 			aria-label="ime"
								// 			value={nameSr}
								// 			required
								// 			onChange={(e) => setNameSr(e.target.value)}
								// 		/>
								// 	</label>
								// 	<label htmlFor="lastnameSr" className="half_width second_width">
								// 		<input
								// 			type="text"
								// 			placeholder="Prezime  *"
								// 			name="lastnameSr"
								// 			aria-label="Prezime "
								// 			value={lastnameSr}
								// 			required
								// 			onChange={(e) => setLastnameSr(e.target.value)}
								// 		/>
								// 	</label>
								// 	<label htmlFor="emailSr">
								// 		<input
								// 			type="email"
								// 			placeholder="E mail *"
								// 			aria-label="E mail sr"
								// 			name="emailSr"
								// 			value={emailSr}
								// 			required
								// 			onChange={(e) => setEmailSr(e.target.value)}
								// 		/>
								// 	</label>
								// 	<label htmlFor="subjectSr">
								// 		<input
								// 			type="subject"
								// 			placeholder="Subjekt *"
								// 			aria-label="Subjekt"
								// 			name="subjectSr"
								// 			value={subjectSr}
								// 			required
								// 			onChange={(e) => setSubjectSr(e.target.value)}
								// 		/>
								// 	</label>
								// 	<label htmlFor="messageSr">
								// 		<textarea
								// 			placeholder="Vaša Poruka *"
								// 			aria-label="Vaša Poruka *"
								// 			name="messageSr"
								// 			rows="8"
								// 			value={messageSr}
								// 			required
								// 			onChange={(e) => setMessageSr(e.target.value)}
								// 		/>
								// 	</label>
								// 	<label htmlFor="submit" className="submitBtnWrapper">
								// 		<button className="submitButton" type="submit" aria-label="submit">
								// 			POŠALJI
								// 		</button>
								// 		<p className={`${submited ? 'submited' : ''}`}>
								// 			Hvala, Vasa poruka je poslata!
								// 		</p>
								// 	</label>
								// </form>
							)}
						</div>
					</div>
				) : (
					<>
						<section className="section">
							<ScrollAnimation animateIn="fadeIn" animateOnce>
								<div className="imageVideoHolder">
									<div className="videoImage">
										{template === 'template-about.php' && (
											<div className="videoWrap">
												<iframe
													src="https://player.vimeo.com/video/200660235?background=1&autoplay=1&loop=1&title=0&byline=0&portrait=0"
													frameBorder="0"
													webkitallowfullscreen=""
													mozallowfullscreen=""
													allowFullScreen=""
													loop="1"
													autoPlay="1"
													muted="1"
													width="100%"
													height="75%"
													title="Vimeo Video 200660235"
												/>
											</div>
										)}
									</div>
								</div>
							</ScrollAnimation>
							<div className="content">
								<ScrollAnimation animateIn="fadeIn" animateOnce>
									<div className="titleWrapper">
										<h1 className="title">{title}</h1>
									</div>
								</ScrollAnimation>
								<div className="container fullWidth">
									<ScrollAnimation animateIn="fadeIn" animateOnce>
										<div className="defaultContent" dangerouslySetInnerHTML={{ __html: content }} />
									</ScrollAnimation>
								</div>
								{template === 'template-about.php' && (
									<div className="container fullWidth">
										<ScrollAnimation animateIn="fadeIn" animateOnce>
											<div className="titleWrapper title3dTour">
												<h1 className="title">Explore our 3D Showroom</h1>
											</div>
										</ScrollAnimation>
										<div className="defaultContent virtualTourWrap">
											<ScrollAnimation animateIn="fadeIn" animateOnce>
												<div className="virtualGuideWrap">
													<iframe
														src="https://my.matterport.com/show/?m=VZKTwLbskaY&sm=2&sr=-.75,-.7,-.56&sp=-14.19,17.55,19.73"
														frameBorder="0"
														webkitallowfullscreen=""
														mozallowfullscreen=""
														allowFullScreen=""
														width="100%"
														height="75%"
														title="3d virtual guide tour"
														allow="xr-spatial-tracking"
													/>
												</div>
												<div className="external3dTour">
													Click{' '}
													<a
														href="https://my.matterport.com/show/?m=VZKTwLbskaY"
														className="external3dTourLink"
														target="_blank"
														rel="noreferrer"
													>
														here
													</a>{' '}
													to check out our showcase as a standalone.
												</div>
											</ScrollAnimation>
										</div>
									</div>
								)}
							</div>
						</section>
					</>
				)}
			</div>
		</section>
	);
};

PageTemplateEn.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	pageCurrentLang: PropTypes.string,
	template: PropTypes.string,
	featImage: PropTypes.object,
};

const Page = ({ data, pageContext }) => {
	const { wordpressPage: page } = data;
	return (
		<div>
			{pageContext.locale === 'en_US' ? <NavigationEn /> : <Navigation />}
			<PageTemplateEn
				title={page.title}
				content={page.content}
				template={page.template}
				featImage={page.featured_media ? page.featured_media : null}
				pageCurrentLang={page.wpml_current_locale}
			/>
			<Footer />
		</div>
	);
};

Page.propTypes = {
	data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
	query PageByIdEn($id: String!) {
		wordpressPage(id: { eq: $id }) {
			title
			content
			template
			featured_media {
				localFile {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
						fixed {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
			wpml_current_locale
			wpml_translations {
				href
				locale
				post_title
				wordpress_id
			}
		}
	}
`;
